@import 'scss/Variables.scss';
.FolderRow{
    $tabHeight: 50px;
    margin-top: -$tabHeight;
    .tab{
        height: $tabHeight;
        padding: 0;
        svg{
            position: absolute;
            height: 100%;
        }
    }
}