@import "scss/Variables.scss";
.menu-component {
    padding: 0;

    .dropdown-menu {
        border: none;
    }

    .nav-link{
        color: $white;
    }

    .active-link{
        background-color: $dark-blue;
        border-radius: 5px;
    }

    a{
        color: $white !important;
    }

    .dropdown-toggle.nav-link,
    .dropdown-item-text {
        color: $white !important;
    }
    .navbar-nav .dropdown-menu {
        box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.1);
        background: $blue;
        color: $white;
    }
    .dropdown-item {
        color: $white !important;
    }
    .dropdown-item:hover,
    .dropdown-item:focus {
        background-color: $dark-blue !important;
    }
}