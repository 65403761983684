//$dark-blue: #00113E;
$blue: #006699;
$dark-blue: darken($blue, 20);
$light-blue: lighten($blue, 20);
//light-blue: #5CD4FB;
$white: #FFFFFF;
$black: #000000;

$theme-colors: (
    "black": $black,
    "dark-blue": $dark-blue,
    "blue": $blue,
    "light-blue": $light-blue,
    "white": $white
);