@import "scss/Variables.scss";
.FloatButton {
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-color: #08a685;
    i{
        font-size: 25px;
        color: $white;
        margin: 13px 13px;
    }
}
